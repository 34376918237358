<template>
    <div
            v-if="show"
            class="transaction-search"
            :class="navBarShow ? 'transaction-search-no-wechat' : ''"
    >
        <van-field
                v-if="flag == 'transaction'"
                v-model="HsValue"
                label-align="right"
                clearable
                :label="$t('HS编码')"
                :placeholder="$t('HS编码')"
        />
        <van-field
                v-model="goodsValue"
                label-align="right"
                :label="$t('产品')"
                :placeholder="$t('产品')"
                @focus="goodsShow = true"
        >
            <template #right-icon>
                <van-icon name="close" @click.stop="clearPicker('goods')"/>
                <div v-if="goodsColumns.length > 0 && goodsShow" class="picker-main">
                    <div
                            v-for="(item, index) in goodsColumns"
                            :key="'goods' + index"
                            class="picker-list"
                            @click="selectPicker('goods', item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </template>
        </van-field>
        <van-field v-if="flag == 'transaction'"
                readonly
                clickable
                :label="$t('类型')"
                :value="typeValue"
                :placeholder="$t('不限')"
                right-icon="arrow"
                @click="showTypePicker = true"
        ></van-field>
        <van-popup v-model="showTypePicker" round position="bottom">
            <van-checkbox-group
                    v-model="typeSel"
                    @change="selType"
            >
                <van-cell-group>
                    <van-cell
                            v-for="(item,index) in typeColumns"
                            clickable
                            :key="index"
                            :name="item"
                            :title="item.name"
                            @click="typetoggle(index)"
                    >
                        <template #right-icon>
                            <van-checkbox :name="item" ref="checkboxesType" />
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-checkbox-group>
        </van-popup>

        <van-field
                v-if="flag == 'supplier'||flag == 'transaction'"
                v-model="supValue"
                label-align="right"
                :label="$t('供应商')"
                :placeholder="$t('供应商')"
                @focus="supShow = true"
        >
            <template #right-icon>
                <van-icon name="close" @click.stop="clearPicker('sup')"/>
                <div v-if="supColumns.length > 0 && supShow" class="picker-main">
                    <div
                            v-for="(item, index) in supColumns"
                            :key="'sup' + index"
                            class="picker-list"
                            @click.stop="selectPicker('sup', item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </template>
        </van-field>
        <van-field
                v-if="flag == 'customer'"
                v-model="cusValue"
                label-align="right"
                :label="$t('客户')"
                :placeholder="$t('客户')"
                @focus="cusShow = true"
        >
            <template #right-icon>
                <van-icon name="close" @click.stop="clearPicker('cus')"/>
                <div v-if="cusColumns.length > 0 && cusShow" class="picker-main">
                    <div
                            v-for="(item, index) in cusColumns"
                            :key="'sup' + index"
                            class="picker-list"
                            @click.stop="selectPicker('cus', item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </template>
        </van-field>

        <template v-if="flag == 'supplier' && showMore">
            <van-field
                    v-model="cert_file"
                    label-align="right"
                    clearable
                    :label="$t('产品证书')"
                    :placeholder="$t('产品证书')"
            />
            <van-field
                    v-model="spec_pha"
                    label-align="right"
                    clearable
                    :label="$t('规格属性')"
                    :placeholder="$t('规格属性')"
            />
            <van-field
                    v-model="level"
                    label-align="right"
                    clearable
                    :label="$t('level')"
                    :placeholder="$t('level')"
            />
        </template>
        <van-field v-if="flag == 'transaction'"
                   readonly
                   clickable
                   :label="$t('行业')"
                   :value="indValue"
                   :placeholder="$t('不限')"
                   right-icon="arrow"
                   @click="showIndPicker = true"
        ></van-field>
        <van-popup v-model="showIndPicker" round position="bottom">
            <van-checkbox-group
                    v-model="indSel"
                    @change="selInd"
            >
                <van-cell-group>
                    <van-cell
                            v-for="(item,index) in indColumns"
                            :key="index"
                            :title="item.name"
                            :name="item"
                            @click="indtoggle(index)"
                    >
                        <template #right-icon>
                            <van-checkbox :name="item" ref="checkboxesInd"/>
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-checkbox-group>
        </van-popup>
        <van-field v-if="flag == 'supplier'"
                   readonly
                   clickable
                   :label="$t('类型')"
                   :value="SupTypeValue"
                   :placeholder="$t('不限')"
                   right-icon="arrow"
                   @click="showSupTypePicker = true"
        ></van-field>
        <van-popup v-model="showSupTypePicker" round position="bottom">
            <van-checkbox-group
                    v-model="SupTypeSel"
                    @change="selSupType"
            >
                <van-cell-group>
                    <van-cell
                            v-for="(item,index) in SupTypeColumns"
                            :key="index"
                            :title="item.name"
                            :name="item"
                            @click="SupTypetoggle(index)"
                    >
                        <template #right-icon>
                            <van-checkbox :name="item" ref="checkboxesSupType"/>
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-checkbox-group>
        </van-popup>
        <template v-if="flag == 'customer' && showMore">
            <van-field
                    v-model="cert_file"
                    label-align="right"
                    clearable
                    :label="$t('产品证书')"
                    :placeholder="$t('产品证书')"
            />
            <van-field
                    v-model="spec_pha"
                    label-align="right"
                    clearable
                    :label="$t('规格属性')"
                    :placeholder="$t('规格属性')"
            />
            <van-field
                    v-model="level"
                    label-align="right"
                    clearable
                    :label="$t('level')"
                    :placeholder="$t('level')"
            />
        </template>
        <template v-if="flag == 'transaction'">
            <van-field
                    v-model="cusValue"
                    label-align="right"
                    :label="$t('客户')"
                    :placeholder="$t('客户')"
                    @focus="cusShow = true"
            >
                <template #right-icon>
                    <van-icon name="close" @click.stop="clearPicker('cus')"/>
                    <div v-if="cusColumns.length > 0 && cusShow" class="picker-main">
                        <div
                                v-for="(item, index) in cusColumns"
                                :key="'cus' + index"
                                class="picker-list"
                                @click="selectPicker('cus', item)"
                        >
                            {{ item }}
                        </div>
                    </div>
                </template>
            </van-field>

            <van-field label-align="right" :label="$t('时间')">
                <template #input>
                    <div
                            class="placeholder-title"
                            :style="{ color: time.start_time ? '#323233' : '' }"
                            @click="showTimePicker('start_time')"
                    >
                        {{ time.start_time ? time.start_time : $t("开始时间") }}
                    </div>
                    <div style="margin: 0 10px">-</div>
                    <div
                            class="placeholder-title"
                            :style="{ color: time.end_time ? '#323233' : '' }"
                            @click="showTimePicker('end_time')"
                    >
                        {{ time.end_time ? time.end_time : $t("结束时间") }}
                    </div>
                </template>
            </van-field>
            <template v-if="showMore">
                <van-field
                        v-model="ori_port"
                        label-align="right"
                        clearable
                        :label="$t('原始港口')"
                        :placeholder="$t('原始港口')"
                />
                <van-field
                        v-model="loa_port"
                        label-align="right"
                        clearable
                        :label="$t('途径港口')"
                        :placeholder="$t('途径港口')"
                />
                <van-field
                        v-model="dis_port"
                        label-align="right"
                        clearable
                        :label="$t('目标港口')"
                        :placeholder="$t('目标港口')"
                />
                <van-field label-align="right" :label="$t('Unit price')">
                    <template #input>
                        <van-field
                                v-model="up_from"
                                clearable
                                :placeholder="$t('最低单价')"
                        />
                        <div style="margin: 0 10px">-</div>
                        <van-field
                                v-model="up_to"
                                clearable
                                :placeholder="$t('最高单价')"
                        />
                    </template>
                </van-field>
                <van-field label-align="right" :label="$t('总价')">
                    <template #input>
                        <van-field
                                v-model="tp_from"
                                clearable
                                :placeholder="$t('最低总价')"
                        />
                        <div style="margin: 0 10px">-</div>
                        <van-field
                                v-model="tp_to"
                                clearable
                                :placeholder="$t('最高总价')"
                        />
                    </template>
                </van-field>
            </template>
        </template>
        <div class="more-title" @click="showMore = !showMore">
            {{ showMore ? $t("收起") : $t("高级") }}
        </div>
        <van-button
                color="#5d74f2"
                class="transaction-search-btn"
                @click="search"
        >{{ $t("搜索") }}
        </van-button
        >
        <van-popup
                v-model="time.show"
                position="bottom"
                :style="{ height: 'auto' }"
        >
            <van-datetime-picker
                    v-model="currentDate"
                    type="date"
                    :confirm-button-text="$t('确定')"
                    :cancel-button-text="$t('取消')"
                    :title="$t('选择年月日')"
                    @confirm="timeConfirm"
                    @cancel="time.show = false"
            />
        </van-popup>
    </div>
</template>

<script>
    import utils from "../utils/utils";

    export default {
        name: "TransactionSearch",
        data() {
            return {
                navBarShow: false, // 是否有顶部导航栏
                showMore: false, // 展示更多
                show: true, // 搜索框显示
                // pickerShow: false, // 选择
                currentDate: new Date(),
                HsValue: "", // hs输入值
                goodsValue: "", // 选中产品值
                supValue: "", // 选中供应商值
                cusValue: "", // 选中客户值
                ori_port: "", // 原始港口
                loa_port: "", // 途径港口
                dis_port: "", // 目标港口
                up_from: "", // 最低单价
                up_to: "", // 最高单价
                tp_from: "", // 最低总价
                tp_to: "", // 最高总价
                cert_file: "", // 产品证书
                spec_pha: "", // 规格属性
                level: "", //  等级

                goodsList: [], // 产品总数据
                supList: [], // 供应商总数据
                cusList: [], // 客户列表
                goodsShow: false, // 产品下拉框显示隐藏
                supShow: false, // 供应商下拉框显示隐藏
                cusShow: false, // 客户下拉框显示隐藏
                goodsColumns: [], // 产品选择器显示数据
                supColumns: [], // 供应商选择器显示数据
                cusColumns: [], // 客户选择器显示数据
                timeout: "", // 节流定时器
                time: {
                    show: false,
                    type: "", // 时间类型
                    start_time: "", // 开始时间
                    end_time: "", // 结束时间
                },
                showTypePicker:false,
                typeColumns: [],
                typeSel:[],
                typeValue:"",
                cate_ids:[],
                //行业选择
                showIndPicker:false,
                indColumns: [],
                indSel:[],
                ind_ids:[],
                indValue:"",
                //供应商类型选择
                //行业选择
                showSupTypePicker:false,
                SupTypeColumns: [
                    {value:"不限",name:this.$t("不限")},
                    {value:"工厂",name:this.$t("工厂")},
                    {value:"贸易",name:this.$t("贸易")},
                ],
                SupTypeSel:[],
                character:[],
                SupTypeValue:"",
            };
        },
        props: {
            flag: {
                type: String,
                default: "", // supplier：供应商搜索，transaction：交易数据,customer:客户搜索
            },
        },
        watch: {
            goodsValue(val) {
                this.pickerkeywordInput("goods", val);
            },
            supValue(val) {
                this.pickerkeywordInput("sup", val);
            },
            cusValue(val) {
                this.pickerkeywordInput("cus", val);
            },
        },
        mounted() {
            this.time.start_time = this.calStartTime();
            if (!utils.isWechat()) {
                this.navBarShow = true;
            }
            this.time.end_time = this.curentTime();
            this.getSearchInint();
        },
        methods: {
            //获取搜索数据
            getSearchInint(){
                let that = this;
                this.$http.post("/v1/initSearchData").then((res) => {
                    if (res.code == 200) {
                        that.typeColumns = res.data.hscodeCate;
                        that.indColumns = res.data.indList;
                        that.typeColumns.unshift({id:"不限",name:that.$t('不限')});
                        that.indColumns.unshift({id:"不限",name:that.$t('不限')});
                    }
                });
            },
            selType(selItems){
                let that = this;
                var cate_ids = [];
                var typeValue = "";
                selItems.forEach(function (item) {
                    cate_ids.push(item.id);
                    typeValue += typeValue?","+item.name:item.name;
                });
                that.cate_ids = cate_ids;
                that.typeValue = typeValue;
            },
            selInd(selItems){
                let that = this;
                var ind_ids = [];
                var indValue = "";
                selItems.forEach(function (item) {
                    ind_ids.push(item.id);
                    indValue += indValue?","+item.name:item.name;
                });
                that.ind_ids = ind_ids;
                that.indValue = indValue;
            },
            selSupType(selItems){
                let that = this;
                var character = [];
                var SupTypeValue = "";
                selItems.forEach(function (item) {
                    character.push(item.value);
                    SupTypeValue += SupTypeValue?","+item.name:item.name;
                });
                that.character = character;
                that.SupTypeValue = SupTypeValue;
            },
            typetoggle(index) {
                this.$refs.checkboxesType[index].toggle();
            },
            indtoggle(index) {
                this.$refs.checkboxesInd[index].toggle();
            },
            SupTypetoggle(index) {
                this.$refs.checkboxesSupType[index].toggle();
            },
            toggleShow() {
                // 搜索框显示隐藏
                this.show = !this.show;
            },
            search() {
                // 搜索
                this.$emit("search", {
                    hs: this.HsValue,
                    pro: this.goodsValue,
                    cus: this.cusValue,
                    sup: this.supValue,
                    start_time: this.time.start_time,
                    end_time: this.time.end_time,
                    ori_port: this.ori_port,
                    loa_port: this.loa_port,
                    dis_port: this.dis_port,
                    up_from: this.up_from,
                    up_to: this.up_to,
                    tp_from: this.tp_from,
                    tp_to: this.tp_to,
                    cert_file: this.cert_file,
                    spec_pha: this.spec_pha,
                    level: this.level,
                    ind_ids:this.ind_ids,
                    cate_ids:this.cate_ids,
                    character:this.character
                });
                this.show = false;
            },
            clearPicker(type) {
                // 清楚选择
                this[type + "Value"] = "";
            },
            selectPicker(type, value) {
                // 选择下拉项
                this[type + "Value"] = value;
                this[type + "Columns"] = [];
                this[type + "Show"] = false;
            },
            showTimePicker(type) {
                // 时间选择框显示
                this.time.type = type;
                this.time.show = true;
            },
            pickerInput() {
                this.debounce(this.pickerkeywordInput, 1000);
            },
            pickerkeywordInput(type, val) {
                //关键字变化
                if (val.length < 4) {
                    this[type + "Columns"] = [];
                    return;
                }
                switch (type) {
                    case "goods":
                        this.poolGoods(val);
                        break;
                    case "cus":
                        this.poolCus(val);
                        break;
                    case "sup":
                        this.poolSup(val);
                        break;
                }
            },
            debounce(fn, wait) {
                if (this.timeout !== null) {
                    clearTimeout(this.timeout);
                }
                this.timeout = setTimeout(fn, wait);
            },
            onConfirm(val) {
                // 确认选择
                this[this.type + "Value"] = val;
                this.pickerShow = false;
            },
            timeConfirm(val) {
                // 确认时间选择
                this.time[this.time.type] = utils.formatDate(val);
                this.time.show = false;
            },
            poolGoods(val) {
                // 公共产品
                this.$http.post("/v1/poolGoods", {val: val}).then((res) => {
                    if (res.code == 200) {
                        this.goodsList = res.data.list;
                        let columns = this.goodsList.filter(
                            (item) => item && item.toLowerCase().indexOf(val.toLowerCase()) != -1
                        );
                        this.goodsColumns = columns;
                    }
                });
            },
            poolSup(val) {
                //  供应商列表
                this.$http.post("/v1/poolSup", {val: val}).then((res) => {
                    if (res.code == 200) {
                        this.supList = res.data.list;
                        let columns = this.supList.filter(
                            (item) => item && item.toLowerCase().indexOf(val.toLowerCase()) != -1
                        );
                        this.supColumns = columns;
                    }
                });
            },
            poolCus(val) {
                // 客户列表
                this.$http.post("/v1/poolCus", {val: val}).then((res) => {
                    if (res.code == 200) {
                        this.cusList = res.data.list;
                        let columns = this.cusList.filter(
                            (item) => item && item.toLowerCase().indexOf(val.toLowerCase()) != -1
                        );
                        this.cusColumns = columns;
                    }
                });
            },
            curentTime() {
                var now = new Date();
                var year = now.getFullYear(); //年
                var month = now.getMonth() + 1; //月
                var day = now.getDate(); //日
                var clock = year + "-";
                if (month < 10) clock += "0";
                clock += month + "-";
                if (day < 10) clock += "0";
                clock += day + " ";
                return clock;
            },
            calStartTime() {
                var now = new Date();
                var year = now.getFullYear(); //年
                var month = now.getMonth() + 1; //月
                var clock = year;

                month = month - 3;
                if (month < 0) {
                    month = month + 12;
                    clock = clock - 1;
                }
                clock += "-";
                if (month < 10) clock += "0";
                clock += month + "-";
                clock += "01";
                return clock;
            },
        },
        // props: {
        //   msg: String
        // }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .transaction-search {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        padding-bottom: 60px !important;
        box-sizing: border-box;
        overflow-y: auto;
    }

    .transaction-search-no-wechat {
        top: 46px !important;
        height: calc(100vh - 46px);
    }

    .placeholder-title {
        color: #ccc;
    }

    .transaction-search-btn {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        display: block;
        width: 100vw;
        height: 40px;
        box-sizing: border-box;
    }

    .picker-main {
        position: absolute;
        top: 33px;
        right: 0;
        width: 100%;
        border: 1px solid #ccc;
        z-index: 1;
        background: rgb(0, 0, 0, 0.6);
        border-radius: 3px;
        max-height: 75vh;
        overflow: auto;
        color: #FFF;
    }

    .picker-list {
        text-align: left;
        padding: 3px 10px;
        border-bottom: 1px solid #e1e1e1;
    }

    .van-cell {
        overflow: initial !important;
    }

    .more-title {
        text-align: center;
        margin-top: 5vw;
        color: royalblue;
    }
</style>
